export const maxWidthTablet = "(max-width: 62em)"
export const maxWidthLaptopSmall = "(max-width: 80em)"
export const maxWidthMobile = "(max-width: 39em)"
export const maxWidthMobileSmall = "(max-width: 23em)"
export const maxWidthMobileMedium = "(max-width: 26em)"
export const minWidthLaptop = "(min-width: 100em)"

export enum RoutesOptions {
  ROOT = "/",
  CAREER = "/despre-camelia-pau",
  SERVICES = "/domeniu-activitate",
  BLOG = "/blog",
  POST_1 = "/blog/articol-umanitate",
  POST_2 = "/blog/articol-avocatul-ajutor-neprețuit",
  POST_3 = "/blog/articol-firmele-nu-mai-sunt-firme",
  POST_4 = "/blog/articol-să-căutăm-dreptatea-în-echilibru",
  POST_5 = "/blog/articol-avocat-cu-drag-de-oameni",
  POST_6 = "/blog/articol-când-banii-sunt-mai-importanți",
}
export const monthNamesRO = [
  "ianuarie",
  "februarie",
  "martie",
  "aprilie",
  "mai",
  "iunie",
  "iulie",
  "august",
  "septembrie",
  "octombrie",
  "noiembrie",
  "decembrie",
]
export const monthNamesEN = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
]
