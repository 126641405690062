import { Card, Stack, Button, Text, Image, Flex, Grid } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import React, { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { maxWidthTablet } from "../../constants"

export const CardMostRecentArticle: FunctionComponent<{
  mostRecentArticle: any
}> = ({ mostRecentArticle }) => {
  const { photo, date, link, title, content } = mostRecentArticle
  const isTablet = useMediaQuery(maxWidthTablet)
  const navigate = useNavigate()
  const { t } = useTranslation()
  return (
    <Stack>
      <Text
        align="left"
        size={isTablet ? "md" : "xs"}
        ff="Barlow"
        mt={"md"}
        fw={400}
        c="#b99350"
        transform="uppercase"
      >
        {t("blog_title_most_recent_article")}
      </Text>
      <Card p={isTablet ? 0 : 5}>
        <Grid
          align="center"
          style={{
            gap: isTablet ? 0 : "3rem",
            flexDirection: isTablet ? "column" : "row",
          }}
        >
          <Grid.Col span={isTablet ? 12 : 5}>
            <Image
              src={photo}
              radius="md"
              height={isTablet ? "100%" : "24rem"}
              p={5}
            />
          </Grid.Col>
          <Grid.Col span={isTablet ? 12 : 6}>
            <Stack justify="left" spacing={6} p="xs">
              <Flex>
                <Text
                  align="left"
                  size={isTablet ? "sm" : "large"}
                  color="#926604"
                  fw={500}
                  style={{ fontStyle: "italic" }}
                  ff="Barlow"
                >
                  {date}
                </Text>
              </Flex>

              <Text
                align="left"
                size={"md"}
                color="#4b5663"
                fw={600}
                ff="Barlow"
              >
                {title}
              </Text>
              <Text
                align="left"
                size={isTablet ? "sm" : "large"}
                color="#4b5663"
                fw={400}
                lineClamp={3}
                ff="Barlow"
              >
                {content}
              </Text>
              <Flex w="100%" justify={isTablet ? "flex-end" : "flex-start"}>
                <Button
                  component="a"
                  radius="md"
                  size={isTablet ? "xs" : "md"}
                  color="golden"
                  className="button"
                  w="fit-content"
                  mt="md"
                  onClick={() => navigate(link)}
                >
                  <Text size={isTablet ? "lg" : "medium"} fw={400} ff="Barlow">
                    {t("button_read_more")}
                  </Text>
                </Button>
              </Flex>
            </Stack>
          </Grid.Col>
        </Grid>
      </Card>
    </Stack>
  )
}
